import axios from "axios";
axios.defaults.baseURL = 'https://api.playerverse.gg:5001'; // Adjust if your backend is hosted elsewhere
axios.defaults.withCredentials = true; // Make sure credentials are sent with requests

const api = {
  async linkDiscord() {
    try {
      // Redirect the user to Discord OAuth2 URL
      const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=1280334043984167044&redirect_uri=http://api.playerverse.gg:5001/api/discord/callback&response_type=code&scope=identify`;

      // Perform the redirect
      window.location.href = discordAuthUrl;
    } catch (error) {
      console.error("Error during Discord linking:", error);
      throw error;
    }
  },
  async uploadTeamBanner(formData) {
    if (!formData) {
      console.log("No File Uploaded");
      return null;
    }

    try {
      const response = await axios.post("/api/upload-team-banner", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error uploading banner:", error);
      throw error;
    }
  },
  async uploadTeamAvatar(formData) {
    if (!formData) {
      console.log("No File Uploaded");
      return null; // Explicitly return null
    }

    try {
      // Post the formData directly to your backend API
      const response = await axios.post("/api/upload-team-avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Axios sets this automatically, but it's okay to include
        },
      });

      return response.data; // Return the entire response to handle the URL and any other data
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Propagate the error for further handling
    }
  },
  async getVetoData(matchId) {
    try {
      const response = await axios.get(`/api/matches/${matchId}/veto-data`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching veto data:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async voteMap(matchId, mapIndex, userteam) {
    try {
      const response = await axios.post(`/api/matches/${matchId}/vote-map`, {
        mapIndex,
        userteam,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error voting map:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async pickSide(matchId, side) {
    try {
      const response = await axios.post(`/api/matches/${matchId}/pick-side`, {
        side,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error picking side:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async handleAction(matchId) {
    try {
      const response = await axios.post(
        `/api/matches/${matchId}/handle-action`
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error handling action:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async saveVetoResults(matchId, vetoResults) {
    try {
      const response = await axios.post(
        `/api/matches/${matchId}/veto-results`,
        vetoResults
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error saving veto results:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async createChallenge(challengerId, teamId) {
    try {
      const response = await axios.post("/api/challenges/create", {
        challengerId,
        teamId,
      });
      return response.data;
    } catch (error) {
      console.error("Error creating challenge:", error.response ? error.response.data : error.message);
      throw error;
    }
  },
  async acceptChallenge(challengeId) {
    try {
      const response = await axios.post("/api/challenges/accept", {
        challengeId,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error accepting challenge:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async rejectChallenge(challengeId) {
    try {
      const response = await axios.post("/api/challenges/reject", {
        challengeId,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error rejecting challenge:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getGeoLocation() {
    try {
      const response = await axios.get("/api/fastest-region");
      return response.data;
    } catch (error) {
      console.error(
        "Error getting geo-location:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async updateUserRegion(userId, region) {
    try {
      const response = await axios.post("/api/user/update-region", {
        userId,
        region,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error updating user region:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async checkIfUserExists(steamId) {
    try {
      const response = await axios.get(`/api/check-user/${steamId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error checking if user exists:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async sendTeamInvite({ friendId, teamId }) {
    try {
      const response = await axios.post("/api/teams/invite", { friendId, teamId });
      return response.data;
    } catch (error) {
      console.error(
        "Error sending team invite:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async kickTeamMate({ friendId, teamId }) {
    try {
      const response = await axios.post("/api/teams/player/kick", { friendId, teamId });
      return response.data;
    } catch (error) {
      console.error(
        "Error sending team invite:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async deleteTeam(teamId) {
    try {
      const response = await axios.delete(`/api/teams/delete/${teamId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting team:", error);
      throw error;
    }
  },
  async deleteUser() {
    try {
      const response = await axios.delete(`/api/player/delete`);
      return response.data;
    } catch (error) {
      console.error("Error deleting team:", error);
      throw error;
    }
  },
  async getTeamInvite(teamId) {
    try {
      const response = await axios.get(`/api/teams/invites/${teamId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching team invites:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getPlayerTeamInvite(steamId) {
    try {
      const response = await axios.get(`/api/player/teams/invites/${steamId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching team invites:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  // Accept team invite
async acceptTeamInvite(inviteId) {
  try {
    const response = await axios.post(`/api/player/teams/invites/accept/${inviteId}`);
    return response.data;
  } catch (error) {
    console.error("Error accepting team invite:", error);
    throw error;
  }
},
// Decline team invite
async declineTeamInvite(inviteId) {
  try {
    const response = await axios.post(`/api/player/teams/invites/decline/${inviteId}`);
    return response.data;
  } catch (error) {
    console.error("Error declining team invite:", error);
    throw error;
  }
},
  async createTeam(teamData) {
    try {
      const response = await axios.post("/api/teams/create", teamData);
      return response.data;
    } catch (error) {
      console.error(
        "Error creating team:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getTeamProfile(teamid) {
    try {
      const response = await axios.get(`/api/team-profile/${teamid}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching team:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async updateTeamProfile(teamId, formData) {
    try {
      const response = await axios.post(
        `/api/team-profile/update/${teamId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error updating team profile:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getAllTeams() {
    try {
      const response = await axios.get("/api/teams");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching teams:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getAllUsers() {
    try {
      const response = await axios.get("/api/users");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching users:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async createMatch(matchData) {
    try {
      const response = await axios.post("/api/matches/create", matchData);
      return response.data;
    } catch (error) {
      console.error(
        "Error creating match:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getPlayerSummaries(steamId) {
    try {
      const response = await axios.get(`/api/steam/player-profiles/${steamId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching player summaries:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getPlayerStats(steamId) {
    try {
      const response = await axios.get(`/api/player-stats/${steamId}`);
      return response.data.playerstats;
    } catch (error) {
      console.error(
        "Error fetching player stats:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getCsgoStats(steamId) {
    try {
      const response = await axios.get(`/api/game-stats/${steamId}`);
      return response.data.profile.rankings;
    } catch (error) {
      console.error(
        "Error fetching CS:GO stats:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async joinQueue() {
    try {
      const response = await axios.post("/api/enter-queue");
      return response.data;
    } catch (error) {
      console.error("Error joining queue:", error);
      throw error;
    }
  },
  async leaveQueue() {
    try {
      const response = await axios.post("/api/leave-queue");
      return response.data;
    } catch (error) {
      console.error("Error leaving queue:", error);
      throw error;
    }
  },
  async getQueueCount() {
    try {
      const response = await axios.get("/api/queue-count");
      return response.data;
    } catch (error) {
      console.error("Error fetching queue count:", error);
      throw error;
    }
  },
  async ConfirmMatch(matchId, confirmedTime) {
    try {
      // Prepare the data payload
      const data = {
        matchId: matchId,
        confirmedTime: confirmedTime, // The confirmed time in UTC
      };
  
      // Make the POST request to submit the confirmed match time
      const response = await axios.post('/api/challenges/confirm-match', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Return the server's response data
      return response.data;
    } catch (error) {
      console.error('Error confirming match:', error);
      throw error; // Rethrow error for handling in the calling function
    }
  },  
  async ScheduleMatch(matchId, timeSlots) {
    try {
      // Prepare the data payload
      const data = {
        matchId: matchId,
        timeSlots: timeSlots, // Assuming timeSlots is an array of ISO strings
      };
  
      // Make the POST request to submit the match schedule
      const response = await axios.post('/api/challenges/schedule-match', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Return the server's response data
      return response.data;
    } catch (error) {
      console.error('Error scheduling match:', error);
      throw error; // Rethrow error for handling in the calling function
    }
  },  
  async SendPingResults(data) {
    try {
      // Use POST to send the ping results to the server
      const response = await axios.post('/submit-ping-results', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data; // Return the server's response data
    } catch (error) {
      console.error('Error sending ping results:', error);
      throw error; // Rethrow error for handling in the calling function
    }
  },
  async checkQueueStatus() {
    try {
      const response = await axios.get("/api/queue/status");
      return response.data;
    } catch (error) {
      console.error(
        "Error checking login status:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async checkLoginStatus() {
    try {
      const response = await axios.get("/api/steam/check-login");
      return response.data;
    } catch (error) {
      console.error(
        "Error checking login status:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async initiateSteamLogin() {
    try {
      window.location.href = "https://api.playerverse.gg:5001/api/steam/auth";
    } catch (error) {
      console.error(
        "Error initiating Steam login:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async updateStatus(steamId, status) {
    try {
      const response = await axios.post("/api/steam/update-status", {
        steamId,
        status,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error updating status:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async logout() {
    try {
      const response = await axios.get("/api/steam/logout");
      return response.data;
    } catch (error) {
      console.error(
        "Error logging out:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getFriends() {
    try {
      const response = await axios.get('/api/friends/list');
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching friends:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getFriendRequests() {
    try {
      const response = await axios.get("/api/friends/requests");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching friend requests:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getChallengeRequests(steamId) {
    try {
      const response = await axios.get(`/api/challenges/requests/${steamId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching challenge requests:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getPartyInvitations() {
    try {
      const response = await axios.get("/api/party/invitations");
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching party invitations:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getSentPartyInvitations() {
    try {
      const response = await axios.get("/api/party/invitations/sent");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching party invitations:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getMatches() {
    try {
      const response = await axios.get("/api/matches");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching matches:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getTourns() {
    try {
      const response = await axios.get("/api/tourns");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching tournoments:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getWagerMatches() {
    try {
      const response = await axios.get("/api/matches/wagers");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching matches:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async advanceStage(matchId) {
    try {
      const response = await axios.post(`/api/matches/${matchId}/advance-stage`);
      return response.data;
    } catch (error) {
      console.error('Error advancing stage:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
  async getMatchDetails(matchId) {
    try {
      const response = await axios.get(`/api/matches/details/${matchId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching match details:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getSentChallenges(userid) {
    try {
      const response = await axios.get(`/api/challenges/sent/${userid}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching sent challenges:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async checkIfInParty() {
    try {
      const response = await axios.get("/api/party/members/check");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching party members:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getRecentMessages(userId) {
    try {
      const response = await axios.get(`/api/messages/recent?userId=${userId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching recent messages:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async sendMessages(senderId, receiverId, message) {
    try {
      const response = await axios.post("/api/messages/send", {
        senderId,
        receiverId,
        message,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getChatHistory(friendId) {
    try {
      const response = await axios.get(`/api/messages/history/${friendId}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching chat history:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async addFriend(friendId) {
    try {
      const response = await axios.post("/api/friends/add", { friendId });
      console.log("addFriend response:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error adding friend:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async acceptFriend(requestId) {
    try {
      const response = await axios.post("/api/friends/accept", { requestId });
      console.log(requestId);
      return response.data;
    } catch (error) {
      console.error(
        "Error accepting friend request:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async rejectFriend(requestId) {
    try {
      const response = await axios.post("/api/friends/reject", { requestId });
      console.log(requestId);
      return response.data;
    } catch (error) {
      console.error(
        "Error rejecting friend request:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async searchUsers(query) {
    try {
      const response = await axios.get(`/api/users/search?query=${query}`);
      return response.data;
    } catch (error) {
      console.error(
        "Error searching users:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async getUnreadMessages() {
    try {
      const response = await axios.get("/api/messages/unread");
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching unread messages:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async markMessagesAsRead(senderId) {
    try {
      const response = await axios.post("/api/messages/mark-as-read", {
        senderId,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error marking messages as read:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async sendPartyInvitation(friendId) {
    try {
      const response = await axios.post("/api/party/invite", {
        userId: friendId,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error sending party invitation:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async acceptPartyInvitation(invitationId) {
    try {
      const response = await axios.post("/api/party/accept", { invitationId });
      return response.data;
    } catch (error) {
      console.error(
        "Error accepting party invitation:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async rejectPartyInvitation(invitationId) {
    try {
      const response = await axios.post("/api/party/reject", { invitationId });
      return response.data;
    } catch (error) {
      console.error(
        "Error rejecting party invitation:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async removePartyMember(memberId) {
    try {
      const response = await axios.post("/api/party/remove-member", {
        memberId,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error removing party member:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async leaveParty() {
    try {
      const response = await axios.post("/api/party/leave");
      return response.data;
    } catch (error) {
      console.error(
        "Error leaving party:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async startVeto(matchId, region) {
    try {
      const response = await axios.post(`/api/matches/${matchId}/start-veto`, {
        region,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error starting veto:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async acceptMatch(matchId) {
    try {
      const response = await axios.post(`/api/matches/${matchId}/accept`);
      return response.data;
    } catch (error) {
      console.error(
        "Error accepting match:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
  async removePlayerFromMatch(matchId, steamId) {
    try {
      const response = await axios.post(
        `/api/matches/${matchId}/remove-player`,
        { steamId }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error removing player from match:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  },
};

export default api;
