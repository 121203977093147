<template>
  <div>
    <div class="sticky-header">
      <div v-if="isLoading">
      </div>
      <div v-else-if="matchDetails.status === 'pending'">
      </div>
      <div v-else>
        <ul class="page-tab-menu flex">
          <li :class="{ 'tab-active': currentStage === 1 }">{{ team1Details ? team1Details : '' }} Ban 2</li>
          <li :class="{ 'tab-active': currentStage === 2 }">{{ team2Details ? team2Details : '' }} Ban 3</li>
          <li :class="{ 'tab-active': currentStage === 3 }">{{ team1Details ? team1Details : '' }} Ban 1</li>
          <li :class="{ 'tab-active': currentStage === 4 }">{{ team2Details ? team2Details : '' }} Picks Side</li>
          <li :class="{ 'tab-active': currentStage === 5 }">Server Details</li>
        </ul>
      </div>
    </div>
    <div v-if="isLoading" class="flex center h-70">
      <div class="spinner"></div>
    </div>
    <div v-else-if="matchDetails.status === 'pending'" class="flex center h-70">
      <div v-if="matchDetails.pendingSchedule" class="flex column center">
        <div v-if="this.userteam === 1">
          <h4>Please Select a Time For Your Match</h4>

          <!-- Display the three proposed times in local time -->
          <div v-if="proposedTimes.length">
            <div v-for="(time, index) in localTimeSlots" :key="index" class="time-option">
              <button @click="selectTime(time)" :class="{ selected: selectedTime === time }">
                {{ time }}
              </button>
            </div>
          </div>

          <!-- Confirm Match Button -->
          <button @click="confirmMatch" :disabled="!selectedTime">Confirm Match</button>
        </div>
      </div>
      <div v-else-if="matchDetails.schedule === 'pending'" class="flex column center">
        <div v-if="this.userteam === 2">
          <h4>Please Schedule Your Match</h4>

          <!-- Display the 7-Day Selection Grid -->
          <div class="day-grid">
            <button v-for="(day, index) in nextSevenDays" :key="index" @click="selectDay(day)"
              :disabled="dayAlreadySelected(day)">
              {{ formatDate(day) }}
            </button>
          </div>

          <!-- Time Slot Selector (Appears after a day is selected) -->
          <div v-if="selectedDay" class="time-slot-selector">
            <h5>Select a Time for {{ formatDate(selectedDay) }}</h5>
            <div class="time-slot-grid">
              <button v-for="time in availableTimeSlots" :key="time" @click="selectTimeSlot(time)"
                :disabled="timeSlotAlreadySelected(time)">
                {{ formatTime(time) }}
              </button>
            </div>
          </div>

          <!-- Display Selected Time Slots -->
          <div v-if="timeSlots.length > 0" class="selected-time-slots">
            <h5>Selected Time Slots:</h5>
            <ul>
              <li v-for="slot in sortedTimeSlots" :key="slot">{{ formatDate(slot) }} - {{ formatTime(slot) }}</li>
            </ul>
          </div>

          <!-- Submit Button -->
          <button v-if="timeSlots.length === 3" @click="submitSchedule">Submit Schedule</button>

        </div>
        <div v-else>
          <p>Only players from team 2 can schedule the match.</p>
        </div>

      </div>
      <div v-else-if="hasStarted === false" class="flex column center">
        <h4>Scheduled {{ formattedCountdown }}</h4>
        <span class="match-start-span">{{ prettySchedule }}</span>
      </div>
      <div v-else-if="!matchDetails.vetoData" class="flex center column">
        {{ message }}
        <div class="spinner" style="margin-top: 1em"></div>
      </div>
    </div>
    <div v-else>
      <div class="flex center match-container">
        <div class="match-item-container flex column">
          <h4 class="match-team-heading">{{ team1Details ? team1Details : '' }}</h4>
          <ul>
            <li class="flex chat-friend" v-for="player in filteredTeam1Players" :key="player.displayName">
              <div class="friend-info flex">
                <a :href="'/Profile/' + player.steamId" class="chat-link"><img :src="player.avatar"
                    class="chat-friend-avatar" /></a>
                <a :href="'/Profile/' + player.steamId" class="chat-link">{{ player.displayName }}</a>
                <span style="font-weight: 900; font-style: italic">{{ player.elo ? formatNumber(player.elo) : '0K'
                  }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="match-info-container flex column center">
          <div class="timer-container" v-if="currentStage != 5 && hasStarted">
            <div v-if="timer > 0 && matchDetails.vetoData">Time left: {{ timer }} seconds</div>
            <div v-else-if="matchDetails.vetoData" class="spinner"></div>
          </div>
          <template v-if="currentStage < 4 && hasStarted">
            <div class="flex column match-map-container row">
              <div v-for="(map, index) in maps" :key="index" class="match-map-item">
                <img :src="require(`@/assets/maps/pngs/${map.name}.png`)" />
                <div class="overlay">
                  <button v-if="!isDisabled(index)" class="toggle-button" @click="voteMap(index)">
                    <span v-if="!map.isBanned && !map.isVoted"><button class="btn-ban">Ban {{ map.name
                        }}</button></span>
                    <div class="overlay" v-if="map.isVoted"></div>
                  </button>
                  <div class="overlay-dark" v-if="map.isBanned"></div>
                </div>
                <div class="ban-bubble" v-if="map.isBanned">Banned</div>
              </div>
            </div>
          </template>
          <template v-if="currentStage === 4 && hasStarted">
            <div class="side-pick-container flex">
              <div class="match-map-item">
                <div class="overlay">
                  <button @click="pickSide('T')" class="toggle-button"
                    :disabled="isDisabledSidePick || pickedSide === 'T'">
                    <img :src="require('@/assets/maps/pngs/T_logo.png')" />
                    <div v-if="pickedSide === 'T'"></div>
                    <span v-if="pickedSide === 'T'" class="ban-bubble">Picked</span>
                  </button>
                </div>
              </div>
              <div class="match-map-item">
                <div class="overlay">
                  <button @click="pickSide('CT')" class="toggle-button"
                    :disabled="isDisabledSidePick || pickedSide === 'CT'">
                    <img :src="require('@/assets/maps/pngs/CT_logo.png')" />
                    <div v-if="pickedSide === 'CT'"></div>
                    <span v-if="pickedSide === 'CT'" class="ban-bubble">Picked</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="currentStage === 5 && hasStarted">
            <div class="container">
              <ul class="flex table column server-table">
                <div class="table-row flex">
                  <p>Map</p>
                  <p>{{ pickedMap }}</p>
                </div>
                <div class="table-row flex">
                  <p>{{ team2Details ? team2Details : '' }} Start</p>
                  <img class="server-table-img" v-if="pickedSide === 'CT'"
                    :src="require('@/assets/maps/pngs/CT_logo.png')" />
                  <img class="server-table-img" v-else :src="require('@/assets/maps/pngs/T_logo.png')" />
                </div>
                <div class="table-row flex" v-if="this.userteam != 0">
                  <p>Server IP</p>
                  <p id="serverIp">{{ matchDetails.serverIp + ':' + matchDetails.serverPort }}</p>
                  <button class="btn btn-copy flex" @click="copyToClipboard">Copy</button>
                </div>
              </ul>
            </div>
          </template>
        </div>
        <div class="match-item-container flex column">
          <h4 class="match-team-heading">{{ team2Details ? team2Details : '' }}</h4>
          <ul>
            <li class="flex chat-friend" v-for="player in filteredTeam2Players" :key="player.displayName">
              <div class="friend-info flex">
                <a :href="'/Profile/' + player.steamId" class="chat-link"><img :src="player.avatar"
                    class="chat-friend-avatar" /></a>
                <a :href="'/Profile/' + player.steamId" class="chat-link">{{ player.displayName }}</a>
                <span style="font-weight: 900; font-style: italic">{{ player.elo ? formatNumber(player.elo) : '0K'
                  }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";
import { mapState } from 'vuex';
import { getSocket } from '@/socket';
export default {
  name: "MatchDetails",
  data() {
    return {
      maps: [],
      currentStage: 0,
      userteam: '',
      timer: 45,
      userVotes: 0,
      pickedMap: null,
      pickedSide: null,
      matchDetails: '',
      team1Details: {},
      team2Details: {},
      team1Players: [],
      team2Players: [],
      hasStarted: false,
      countdownInterval: null,
      formattedCountdown: '',
      isLoading: true,
      message: 'Veto system starting...',
      timerInterval: null,
      socket: null,
      user: {},
      timeSlots: [], // Will store selected Date objects
      selectedDay: null,
      selectedTime: null, // Holds the selected time
      proposedTimes: []
    };
  },
  computed: {
    ...mapState(['user']),
    filteredTeam1Players() {
      return this.team1Players.filter(player => player !== null);
    },
    filteredTeam2Players() {
      return this.team2Players.filter(player => player !== null);
    },
    localTimeSlots() {
      // Convert each proposed UTC time to the user's local time
      return this.proposedTimes.map(time => this.convertUTCToLocal(time));
    },
    nextSevenDays() {
      const days = [];
      const currentDate = new Date(Date.now() + 24 * 60 * 60 * 1000); // Start 24 hours from now

      for (let i = 0; i < 7; i++) {
        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + i);
        days.push(nextDay);
      }
      return days;
    },
    availableTimeSlots() {
      const timeSlots = [];
      if (this.selectedDay) {
        // Set time slots for each hour of the selected day (on the hour)
        for (let hour = 0; hour < 24; hour++) {
          const slot = new Date(this.selectedDay);
          slot.setHours(hour, 0, 0, 0);
          timeSlots.push(slot);
        }
      }
      return timeSlots;
    },
    sortedTimeSlots() {
    // Return a sorted copy of the timeSlots array
    return [...this.timeSlots].sort((a, b) => new Date(a) - new Date(b));
  },
    isDisabledSidePick() {
      return this.currentStage !== 4 || this.userteam !== 2;
    },
    prettySchedule() {
      if (!this.matchDetails.schedule) return null;

      const scheduleDate = new Date(this.matchDetails.schedule);

      const options = {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };

      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(scheduleDate);
      const [time] = formattedDate.split(', ');

      return `${time}`;
    }
  },
  methods: {
    selectTime(time) {
      this.selectedTime = time; // Set the selected time when the button is clicked
    },
    formatDate(date) {
    // Ensure the date is valid before formatting
    const validDate = new Date(date);
    
    if (isNaN(validDate.getTime())) {
      // Return a fallback value or an empty string if the date is invalid
      return 'Invalid Date';
    }

    return new Intl.DateTimeFormat('en-US', { weekday: 'short', month: 'short', day: 'numeric' }).format(validDate);
  },
  formatTime(date) {
    const validDate = new Date(date);

    // Check if the date is valid before attempting to format
    if (isNaN(validDate.getTime())) {
      console.error('Invalid date value:', date);
      return 'Invalid Time';
    }

    return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(validDate);
  },
    selectDay(day) {
      this.selectedDay = day;
    },
    selectTimeSlot(time) {
      if (this.timeSlots.length < 3) {
        const utcTime = this.convertToUTC(time); 
        this.timeSlots.push(utcTime);
        this.selectedDay = null; // Reset selected day after selecting a time
      }
    },
    dayAlreadySelected(day) {
      return this.timeSlots.some(slot => new Date(slot).toDateString() === day.toDateString());
    },
    timeSlotAlreadySelected(time) {
      return this.timeSlots.some(slot => new Date(slot).getTime() === time.getTime());
    },
    convertUTCToLocal(utcDateTime) {
      const localDate = new Date(utcDateTime);
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
        hour12: true
      }).format(localDate);
    },
    convertToUTC(localDateTime) {
      return new Date(localDateTime).toISOString(); // Converts to UTC
    },
    convertLocalToUTC(localDateTime) {
      const localDate = new Date(localDateTime);
      return localDate.toISOString(); // Convert local time back to UTC
    },
    async submitSchedule() {
      const apiPayload = {
        matchId: this.matchDetails._id,
        timeSlots: this.timeSlots, // Send as ISO strings
      };

      try {
        await api.ScheduleMatch(apiPayload.matchId, apiPayload.timeSlots);
        await this.fetchData();
      } catch (error) {
        console.error('Error submitting schedule:', error);
      }
    },
    async confirmMatch() {
      const confirmedTimeUTC = this.convertLocalToUTC(this.selectedTime);

      const apiPayload = {
        matchId: this.matchDetails._id, // Assuming matchDetails is available
        confirmedTime: confirmedTimeUTC // Send the confirmed time in UTC format
      };

      try {
        await api.ConfirmMatch(apiPayload.matchId, apiPayload.confirmedTime);
        await this.fetchData();
      } catch (error) {
        console.error('Error confirming match time:', error);
      }
    },
    copyToClipboard() {
      const serverIp = this.matchDetails.serverIp;
      const serverPort = this.matchDetails.serverPort;
      const command = `connect ${serverIp}:${serverPort};password matchupggpassword`;

      const tempTextarea = document.createElement('textarea');
      tempTextarea.value = command;
      document.body.appendChild(tempTextarea);
      tempTextarea.select();
      document.execCommand('copy');
      document.body.removeChild(tempTextarea);

      alert('Copied to clipboard: ' + command);
    },
    formatNumber(num) {
      if (num === null || num === undefined) return '0K';
      if (num >= 1000000) return (num / 1000000).toFixed(0) + 'M';
      if (num >= 1000) return (num / 1000).toFixed(0) + 'K';
      return num.toString();
    },
    formatCountdown(seconds) {
      if (seconds < 60) {
        return `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}${remainingSeconds > 0 ? ` ${remainingSeconds} ${remainingSeconds === 1 ? 'second' : 'seconds'}` : ''}`;
      } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'}${remainingMinutes > 0 ? ` ${remainingMinutes === 1 ? 'minute' : 'minutes'}` : ''}`;
      } else {
        const days = Math.floor(seconds / 86400);
        const remainingHours = Math.floor((seconds % 86400) / 3600);
        return `${days} ${days === 1 ? 'day' : 'days'}${remainingHours > 0 ? ` ${remainingHours === 1 ? 'hour' : 'hours'}` : ''}`;
      }
    },
    updateCountdown() {
      if (this.countdown > 0) {
        this.countdown -= 1;
        this.formattedCountdown = this.formatCountdown(this.countdown);
      } else {
        clearInterval(this.countdownInterval);
      }
    },
    updateTimer() {
      if (this.timer > 0) {
        this.timer -= 1;
      } else {
        clearInterval(this.timerInterval);
        this.advanceStage(); // Automatically advance the stage when the timer hits 0
      }
    },
    async fetchData() {
      const matchId = this.$route.params.challenge;
      this.isLoading = true;
      try {
        const matchDetails = await api.getMatchDetails(matchId);
        this.matchDetails = matchDetails != null ? matchDetails : matchId;

        if (this.matchDetails.schedule === 'pending') {
          const userStatus = await api.checkLoginStatus();
          this.user = userStatus;
          const steamId = userStatus.user.id;

          if (this.matchDetails.playersTeam1) {
            this.team1Players = await this.fetchPlayerDetails(this.matchDetails.playersTeam1);
            this.team1Details = this.matchDetails.team1;
          }

          if (this.matchDetails.playersTeam2) {
            this.team2Players = await this.fetchPlayerDetails(this.matchDetails.playersTeam2);
            this.team2Details = this.matchDetails.team2;
          }

          const isUserInTeam1 = this.team1Players.some(player => player.steamId === steamId);
          const isUserInTeam2 = this.team2Players.some(player => player.steamId === steamId);

          if (isUserInTeam1) {
            this.userteam = 1;
          } else if (isUserInTeam2) {
            this.userteam = 2;
          } else {
            this.userteam = 0;
          }

          if (this.matchDetails.pendingSchedule) {
            this.proposedTimes = this.matchDetails.pendingSchedule; // Assuming this is where the times are stored
          }

        } else {
          const now = Date.now();
          const scheduleTime = new Date(this.matchDetails.schedule).getTime();

          if (scheduleTime > now) {
            this.countdown = Math.floor((scheduleTime - now) / 1000);
            this.formattedCountdown = this.formatCountdown(this.countdown);
          } else {
            this.hasStarted = true;
            const vetoData = await api.getVetoData(matchId);
            if (vetoData) {

              if (this.matchDetails.playersTeam1) {
                this.team1Players = await this.fetchPlayerDetails(this.matchDetails.playersTeam1);
                this.team1Details = this.matchDetails.team1;
              }

              if (this.matchDetails.playersTeam2) {
                this.team2Players = await this.fetchPlayerDetails(this.matchDetails.playersTeam2);
                this.team2Details = this.matchDetails.team2;
              }

              const userStatus = await api.checkLoginStatus();
              this.user = userStatus;
              const steamId = userStatus.user.id;

              const isUserInTeam1 = this.team1Players.some(player => player.steamId === steamId);
              const isUserInTeam2 = this.team2Players.some(player => player.steamId === steamId);

              if (isUserInTeam1) {
                this.userteam = 1;
              } else if (isUserInTeam2) {
                this.userteam = 2;
              } else {
                this.userteam = 0;
              }

              this.maps = vetoData.maps;
              this.currentStage = vetoData.currentStage;
              this.pickedMap = vetoData.pickedMap;
              this.pickedSide = vetoData.pickedSide;
              this.timer = vetoData.countdown;

              this.countdownInterval = setInterval(this.updateCountdown, 1000);
              this.timerInterval = setInterval(this.updateTimer, 1000);
            } else {
              this.message = "Veto system initiating";
            }
          }
        }


      } catch (error) {
        console.error('Error fetching match details:', error);
        this.matchDetails = error;
      }

      this.isLoading = false;
    },
    async fetchPlayerDetails(playerIds) {
      const playerDetailsPromises = playerIds.map(async (steamId) => {
        return await api.getPlayerSummaries(steamId);
      });
      return await Promise.all(playerDetailsPromises);
    },
    async voteMap(index) {
      const matchId = this.$route.params.challenge;
      try {
        const vetoData = await api.voteMap(matchId, index, this.userteam);
        this.maps = vetoData.maps;
        this.checkAndAdvanceStage(vetoData);
      } catch (error) {
        console.error('Error voting map:', error);
      }
    },
    async pickSide(side) {
      const matchId = this.$route.params.challenge;
      try {
        const vetoData = await api.pickSide(matchId, side);
        this.pickedSide = vetoData.pickedSide;
        this.currentStage = vetoData.currentStage;
      } catch (error) {
        console.error('Error picking side:', error);
      }
    },
    async advanceStage() {
      this.currentStage++;
      await this.fetchData();
    },
    isDisabled(index) {
      const map = this.maps[index];

      const isUserTurnToBan = (this.currentStage === 1 || this.currentStage === 3) && this.userteam === 1 ||
        this.currentStage === 2 && this.userteam === 2;

      const bansAllowed = this.currentStage === 1 ? 2 : (this.currentStage === 2 ? 3 : 3);

      const currentBans = this.maps.filter(map => map.isBannedBy === this.userteam).length;

      return !isUserTurnToBan || map.isBanned || this.currentStage === 4 || currentBans >= bansAllowed;
    },
    setupSocketListeners() {
      // Ensure the socket is initialized before adding listeners
      if (this.socket) {
        this.socket.on('veto-update', (data) => {
          if (data.matchId === this.$route.params.challenge) {
            console.log('Veto Update:', data.vetoData);
            this.matchDetails.vetoData = data.vetoData;
            this.maps = data.vetoData.maps;
            this.currentStage = data.vetoData.currentStage;
            this.pickedMap = data.vetoData.pickedMap;
            this.pickedSide = data.vetoData.pickedSide;
            this.timer = data.vetoData.countdown;

            clearInterval(this.timerInterval);
            this.timerInterval = setInterval(this.updateTimer, 1000);
          }

          if (data.vetoData.currentStage < 5) {
            this.fetchData();
          }
        });
      } else {
        // Retry after 2 seconds if this.socket is null
        console.log('Socket not initialized, retrying in 2 seconds...');
        setTimeout(() => {
          this.setupSocketListeners();
          this.socket = getSocket();
        }, 2000);
      }
    },
  },
  async mounted() {
    try {
      this.socket = await getSocket();
    } catch (error) {
      console.error("Error initializing socket:", error);
    }

    this.fetchData();
    this.countdownInterval = setInterval(this.updateCountdown, 1000);
    this.timerInterval = setInterval(this.updateTimer, 1000);
    this.setupSocketListeners();
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval);
    clearInterval(this.timerInterval);
    if (this.socket) {
      this.socket.disconnect();
    }
  }
};
</script>

<style scoped>
.day-grid, .time-slot-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.selected-time-slots ul {
  list-style-type: none;
  padding: 0;
}

.selected-time-slots li {
  padding: 5px 0;
}

.time-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.time-option button.selected {
  background-color: #4CAF50; /* Highlight the selected button */
  color: white;
}
</style>
